import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../API";
import { toast } from "react-toastify";
import { signPicture } from "./listing";
import { formatCurrency } from "../utils/commonUtils";
import { IDLE, LOADING } from "../utils/constants";

export const fetch_propartyData = createAsyncThunk(
  "/propartyDetails",
  async ({ currency, id, check_in=null, check_out=null}) => {
    try {
      var date_details_query = ''
      if (check_in && check_out) {
        date_details_query = `&check_in=${check_in}&check_out=${check_out}`
      }
      let res = await axiosInstance.get(
        `listings/${id}/?currency=${currency ? currency : "AED"}${date_details_query}`
      );
      if (!res?.data) return res?.data;
      if (!res.data.pictures) return res.data;
      res.data.thumbnails = [];
      res.data.fullResolution = [];
      for (const picture of res.data.pictures) {
        res.data.thumbnails.push(signPicture(picture, true));
        res.data.fullResolution.push(signPicture(picture, false));
      }
      return res.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const post_select_date = createAsyncThunk(
  "/propartyDetails",
  async ({ currency, id }) => {
    try {
      let res = await axiosInstance.get(
        `listings/${id}/?currency=${currency ? currency : "AED"}`
      );
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
export const fetch_calendarPrice = createAsyncThunk(
  "/fetch_calendarPrice",
  async ({ currency, id, checkIn, checkOut }) => {
    try {
      let res = await axiosInstance.get(
        `listings/${id}/calendar/?startDate=${checkIn}&endDate=${checkOut}&currency=${
          currency ? currency : "AED"
        }`
      );
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
export const fetch_price_Date = createAsyncThunk(
  "/fetch_calendar",
  async ({ currency, id, checkIn, checkOut, guests, voucherCode, weeklyCleaning }) => {
    try {
      let urlParams = [
        `pms_listing_id=${id}&guest_count=${guests}&check_in_date=${checkIn}&check_out_date=${checkOut}&currency=${
          currency ? currency : "AED"
        }`,
      ];
      if (voucherCode) {
        urlParams.push(`voucher_code=${voucherCode}`);
      }
      if (weeklyCleaning) {
        urlParams.push(`weekly_cleaning=${weeklyCleaning}`);
      }
      let res = await axiosInstance.get(
        
        `bookings/quote?${urlParams.join("&")}`
      );
      if (res?.data?.data?.line_items) {
        res?.data?.data?.line_items.map((item) => {
          item.currency_converted_amount = formatCurrency(
            item.currency_converted_amount,
            item.currency
          );
          return item;
        });
      }
      
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
export const fetch_propartyPrice_cal = createAsyncThunk(
  "/fetch_calendarPrice/booking",
  async ({ currency, id, startDate, endDate, guests }) => {
    try {
      let res = await axiosInstance.get(
        `listings/${id}/calendar/?startDate=${startDate}&endDate=${endDate}&currency=${
          currency ? currency : "AED"
        }`
      );
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
export const fetch_propartyPrice_cal_static = createAsyncThunk(
  "/fetch_calendarPrice/booking/static",
  async ({ currency, id, startDate, endDate, guests }) => {
    try {
      let res = await axiosInstance.get(
        `listings/${id}/calendar/?startDate=${startDate}&endDate=${endDate}&currency=${
          currency ? currency : "AED"
        }`
      );
      return res?.data;
    } catch (error) {
      // console?.log(error);
    }
  }
);

/** Reserve Property */
export const book_property_cal = createAsyncThunk(
  "/bookings/reserve",
  async (data) => {
    try {
      let res = await axiosInstance.post("bookings/reserve", data);
      return res?.data;
    } catch (error) {
      // console.log(error);
    }
  }
);
/** Update booking details */
export const update_booking_details_cal = createAsyncThunk(
  "/bookings/update",
  async (data) => {
    try {
      let res = await axiosInstance.post("bookings/update", data);
      return res?.data;
    } catch (error) {
      // console.log(error);
    }
  }
);

export const fetch_booking_details_cal = createAsyncThunk(
  "/bookings/payment_page_details/",
  async (bookingCode) => {
    // const currency = localStorage.getItem("query");
    let res = await axiosInstance.get(
      `bookings/payment_page_details?booking_code=${bookingCode}`
    );
    if (res?.data) {
      // Formatting upfront payment line items
      if(res?.data?.data?.line_items?.UPFRONT_PAYMENT) {
        res?.data?.data?.line_items?.UPFRONT_PAYMENT.map((item) => {
          item.currency_converted_amount = formatCurrency(
            item.currency_converted_amount,
            item.currency
          );
          return item;
        });
      }
      // Formatting monthly payment line items
      if(res?.data?.data?.line_items?.MONTHLY_PAYMENT) {

        res?.data?.data?.line_items?.MONTHLY_PAYMENT.map((item) => {
          item.currency_converted_amount = formatCurrency(
            item.currency_converted_amount,
            item.currency
          );
          return item;
        });
      }

      // Formatting payment timeline section - upfront
      if(res?.data?.data?.payment_timeline_section?.UPFRONT_PAYMENT) {

        res?.data?.data?.payment_timeline_section?.UPFRONT_PAYMENT?.timeline.map((item) => {
          item.keyId = item.key;
          item.date = item.due_date;
          item.displayName = item.display_name; 
          item.popupTitle = item.display_name;
          item.popupDate = item.due_date;
          if(item.sub_text) {
            item.subText = item.sub_text;
          }
          return item;
        });
      }
      if(res?.data?.data?.payment_timeline_section?.MONTHLY_PAYMENT) {

        res?.data?.data?.payment_timeline_section?.MONTHLY_PAYMENT?.timeline.map((item) => {
          item.keyId = item.key;
          item.date = item.due_date;
          item.displayName = item.display_name; 
          item.popupTitle = item.display_name;
          item.popupDate = item.due_date;
          if(item.sub_text) {
            item.subText = item.sub_text;
          }
          return item;
        });
      }

    }
    console.log(res?.data);
    return res?.data;
  }
);

export const fetch_payment_intent = createAsyncThunk(
  "/payments/payment_intent",
  async ({ bookingCode, baseUrl, paymentOption }) => {
    try {
      let res = await axiosInstance.get(
        `payments/payment_intent?booking_code=${bookingCode}&base_url=${baseUrl}&payment_option=${paymentOption}`
      );
      return res?.data;
    } catch (error) {
      // console.log(error);
    }
  }
);

/** ==== STRIPE ===== */
export const makeStripPayment = createAsyncThunk(
  "payment/stripe/",
  async (obj) => {
    try {
      let res = await axiosInstance.post("listings/payment/stripe/", obj);
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const confirmReservation = createAsyncThunk(
  "listings/reservation",
  async (obj) => {
    try {
      let res = await axiosInstance.post("listings/reservation", obj);
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
/** ==== STRIPE ===== */

/** +++++ RAZOR PAY +++++ */
export const makeRazorPayment = createAsyncThunk(
  "payment/razorpay/create",
  async (obj) => {
    try {
      let res = await axiosInstance.post(
        "listings/payment/razorpay/create",
        obj
      );
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);

export const confirmReservationRazorPay = createAsyncThunk(
  "payment/razorpay/confirm",
  async (obj) => {
    try {
      let res = await axiosInstance.post(
        "listings/payment/razorpay/confirm",
        obj
      );
      return res?.data;
    } catch (error) {
      console?.log(error);
    }
  }
);
/** +++++ RAZOR PAY +++++ */

const initialState = {
  proparty_data: null,
  proparty_status: IDLE,
  price_wise_data: null,
  price_wise_status: IDLE,
  cal_wise_data: null,
  cal_wise_status: IDLE,
  cal_stripe: null,
  cal_stripe_status: IDLE,
  cal_Reservation: null,
  cal_Reservation_status: IDLE,
  cal_razor_pay: null,
  cal_razor_pay_status: IDLE,
  cal_Reservation_Razor: null,
  cal_Reservation_Razor_status: IDLE,
  cal_wise_data_static: null,
  cal_wise_static_status: IDLE,
  isBookingBreakfastService: false,
  reserve_data: null,
  cal_reserve_status: IDLE,
  reserve_data_error: false,
  booking_data: null,
  booking_data_error: false,
  cal_booking_status: IDLE,
  cal_payment_intent_status: IDLE,
  payment_intent: null,
  update_booking_details_status: IDLE,
  update_booking_details_data: null,
};
export const proparty = createSlice({
  name: "propartyDetails",
  initialState,
  reducers: {
    setBookingBreakfastService: (state, { payload }) => {
      state.isBookingBreakfastService = payload;
    },
    setReserveData: (state, { payload }) => {
      state.reserve_data = payload;
      state.reserve_data_error = false;
    },
    setUpdateBookingDetailsData: (state, { payload }) => {
      state.update_booking_details_data = payload;
    },
    setPriceWiseData: (state, {payload}) => {
      state.price_wise_data = payload;
    },
    setBookingData: (state, {payload}) => {
      state.booking_data = payload;
      state.booking_data_error = false;
    },
    setPaymentIntentData: (state, {payload}) => {
      state.payment_intent = payload;
    }
  },
  extraReducers: {
    [fetch_propartyData.pending]: (state) => {
      state.proparty_status = LOADING;
      state.proparty_data = null;
    },
    [fetch_propartyData.fulfilled]: (state, { payload }) => {
      state.proparty_status = IDLE;
      state.proparty_data = payload;
    },
    [fetch_propartyData.rejected]: (state) => {
      state.proparty_status = IDLE;
    },
    //fetch_price_Date wise price
    [fetch_price_Date.pending]: (state) => {
      state.price_wise_status = LOADING;
    },
    [fetch_price_Date.fulfilled]: (state, { payload }) => {
      state.price_wise_status = IDLE;
      state.price_wise_data = payload?.data;
    },
    [fetch_price_Date.rejected]: (state) => {
      state.price_wise_status = IDLE;
    },
    //fetch_cal_data
    [fetch_propartyPrice_cal.pending]: (state) => {
      state.cal_wise_status = LOADING;
    },
    [fetch_propartyPrice_cal.fulfilled]: (state, { payload }) => {
      state.cal_wise_status = IDLE;
      state.cal_wise_data = payload;
    },
    [fetch_propartyPrice_cal.rejected]: (state) => {
      state.cal_wise_status = IDLE;
    },
    //fetch_cal_data static
    [fetch_propartyPrice_cal_static.pending]: (state) => {
      state.cal_wise_static_status = LOADING;
      state.cal_wise_data_static = null;
    },
    [fetch_propartyPrice_cal_static.fulfilled]: (state, { payload }) => {
      state.cal_wise_static_status = IDLE;
      state.cal_wise_data_static = payload;
    },
    [fetch_propartyPrice_cal_static.rejected]: (state) => {
      state.cal_wise_static_status = IDLE;
    },
    //fetch_stripe_data
    [makeStripPayment.pending]: (state) => {
      state.cal_stripe_status = LOADING;
      state.cal_stripe = null;
    },
    [makeStripPayment.fulfilled]: (state, { payload }) => {
      state.cal_stripe_status = IDLE;
      state.cal_stripe = payload?.results;
      localStorage.setItem("stripeClient", JSON.stringify(payload?.results));
    },
    [makeStripPayment.rejected]: (state) => {
      state.cal_stripe_status = IDLE;
    },

    //fetch_reservation_data
    [confirmReservation.pending]: (state) => {
      state.cal_Reservation_status = LOADING;
      state.cal_Reservation = null;
    },
    [confirmReservation.fulfilled]: (state, { payload }) => {
      state.cal_Reservation_status = IDLE;
      state.cal_Reservation = payload;
      if (payload?.success) {
        // toast(payload?.message, { type: "success" });
        localStorage.setItem("userDetails", JSON.stringify(payload));
        localStorage.removeItem("stripeClient");
        localStorage.removeItem("userDetils");
      } else {
        toast(payload?.message, { type: "error" });
        localStorage.removeItem("stripeClient");
        localStorage.removeItem("userDetils");
      }
    },
    [confirmReservation.rejected]: (state) => {
      state.cal_Reservation_status = IDLE;
    },

    /// RAZOR PAY

    [makeRazorPayment.pending]: (state) => {
      state.cal_razor_pay_status = LOADING;
      state.cal_razor_pay = null;
    },
    [makeRazorPayment.fulfilled]: (state, { payload }) => {
      state.cal_razor_pay_status = IDLE;
      state.cal_razor_pay = payload?.results;
      // localStorage.setItem("razorPay", JSON.stringify(payload?.results));
    },
    [makeRazorPayment.rejected]: (state) => {
      state.cal_razor_pay_status = IDLE;
    },

    [confirmReservationRazorPay.pending]: (state) => {
      state.cal_Reservation_Razor_status = LOADING;
      state.cal_Reservation_Razor = null;
    },
    [confirmReservationRazorPay.fulfilled]: (state, { payload }) => {
      state.cal_Reservation_Razor_status = IDLE;
      state.cal_Reservation_Razor = payload;
      if (payload?.success) {
        window.location.href = `/success-payment`;
        // toast(payload?.message, { type: "success" });
        localStorage.setItem("userDetails", JSON.stringify(payload));
      } else {
        toast(payload?.message, { type: "error" });
      }
    },
    [confirmReservationRazorPay.rejected]: (state) => {
      state.cal_Reservation_Razor_status = IDLE;
    },
    [book_property_cal.rejected]: (state) => {
      state.cal_reserve_status = IDLE;
      state.reserve_data_error = true;
    },
    [book_property_cal.pending]: (state) => {
      state.cal_reserve_status = LOADING;
      state.reserve_data = null;
      state.reserve_data_error = false;
    },
    [book_property_cal.fulfilled]: (state, { payload }) => {
      state.cal_reserve_status = IDLE;
      state.reserve_data = payload;
      state.reserve_data_error = false;
    },
    [update_booking_details_cal.rejected]: (state) => {
      state.update_booking_details_status = IDLE;
    },
    [update_booking_details_cal.pending]: (state) => {
      state.update_booking_details_status = LOADING;
      state.update_booking_details_data = null;
    },
    [update_booking_details_cal.fulfilled]: (state, { payload }) => {
      state.update_booking_details_status = IDLE;
      state.update_booking_details_data = payload;
    },
    [fetch_booking_details_cal.rejected]: (state) => {
      state.cal_booking_status = IDLE;
      state.booking_data_error = true;
    },
    [fetch_booking_details_cal.pending]: (state) => {
      state.cal_booking_status = LOADING;
      state.booking_data = null;
      state.booking_data_error = false;
    },
    [fetch_booking_details_cal.fulfilled]: (state, { payload }) => {
      state.cal_booking_status = IDLE;
      state.booking_data = payload;
      state.booking_data_error = false;
    },
    [fetch_payment_intent.rejected]: (state) => {
      state.cal_payment_intent_status = IDLE;
    },
    [fetch_payment_intent.pending]: (state) => {
      state.cal_payment_intent_status = LOADING;
      state.payment_intent = null;
    },
    [fetch_payment_intent.fulfilled]: (state, { payload }) => {
      state.cal_payment_intent_status = IDLE;
      state.payment_intent = payload;
    },
  },
});
export const {
  setBookingBreakfastService,
  setReserveData,
  setUpdateBookingDetailsData,
  setPriceWiseData,
  setBookingData,
  setPaymentIntentData,
} = proparty.actions;
